/**
 *- SASS file for Lato font
 */
// @font-face {
//   font-family: Lato;
//   src: url('./fonts/lato/Lato-Hairline.woff2') format('woff2'), /* Super Modern Browsers */
//        url('./fonts/lato/Lato-HairlineItalic.woff2') format('woff2'), /* Super Modern Browsers */
//        url('./fonts/lato/Lato-Hairline.woff') format('woff'), /* Pretty Modern Browsers */
//        url('./fonts/lato/Lato-HairlineItalic.woff') format('woff'); /* Pretty Modern Browsers */
//   font-weight: 100;
// }

// @font-face {
//   font-family: Lato;
//   src: url('./fonts/lato/Lato-Thin.woff2') format('woff2'), /* Super Modern Browsers */
//        url('./fonts/lato/Lato-ThinItalic.woff2') format('woff2'), /* Super Modern Browsers */
//        url('./fonts/lato/Lato-Thin.woff') format('woff'), /* Pretty Modern Browsers */
//        url('./fonts/lato/Lato-ThinItalic.woff') format('woff'); /* Pretty Modern Browsers */
//   font-weight: 200;
// }

@font-face {
  font-family: Lato;
  src: url('../fonts/lato/Lato-Light.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/lato/Lato-LightItalic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/lato/Lato-Light.woff') format('woff'), /* Pretty Modern Browsers */
       url('../fonts/lato/Lato-LightItalic.woff') format('woff'); /* Pretty Modern Browsers */
  font-weight: 300;
}

@font-face {
  font-family: Lato;
  src: url('../fonts/lato/Lato-Regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/lato/Lato-Italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/lato/Lato-Regular.woff') format('woff'), /* Pretty Modern Browsers */
       url('../fonts/lato/Lato-Italic.woff') format('woff'); /* Pretty Modern Browsers */
  font-weight: 400;
}

@font-face {
  font-family: Lato;
  src: url('../fonts/lato/Lato-Medium.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/lato/Lato-MediumItalic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/lato/Lato-Medium.woff') format('woff'), /* Pretty Modern Browsers */
       url('../fonts/lato/Lato-MediumItalic.woff') format('woff'); /* Pretty Modern Browsers */
  font-weight: 500;
}

// @font-face {
//   font-family: Lato;
//   src: url('./fonts/lato/Lato-Semibold.woff2') format('woff2'), /* Super Modern Browsers */
//        url('./fonts/lato/Lato-SemiboldItalic.woff2') format('woff2'), /* Super Modern Browsers */
//        url('./fonts/lato/Lato-Semibold.woff') format('woff'), /* Pretty Modern Browsers */
//        url('./fonts/lato/Lato-SemiboldItalic.woff') format('woff'); /* Pretty Modern Browsers */
//   font-weight: 600;
// }

// @font-face {
//   font-family: Lato;
//   src: url('./fonts/lato/Lato-Bold.woff2') format('woff2'), /* Super Modern Browsers */
//        url('./fonts/lato/Lato-BoldItalic.woff2') format('woff2'), /* Super Modern Browsers */
//        url('./fonts/lato/Lato-Bold.woff') format('woff'), /* Pretty Modern Browsers */
//        url('./fonts/lato/Lato-BoldItalic.woff') format('woff'); /* Pretty Modern Browsers */
//   font-weight: 700;
// }

@font-face {
  font-family: Lato;
  src: url('../fonts/lato/Lato-Heavy.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/lato/Lato-HeavyItalic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/lato/Lato-Heavy.woff') format('woff'), /* Pretty Modern Browsers */
       url('../fonts/lato/Lato-HeavyItalic.woff') format('woff'); /* Pretty Modern Browsers */
  font-weight: 800;
}

// @font-face {
//   font-family: Lato;
//   src: url('./fonts/lato/Lato-Black.woff2') format('woff2'), /* Super Modern Browsers */
//        url('./fonts/lato/Lato-BlackItalic.woff2') format('woff2'), /* Super Modern Browsers */
//        url('./fonts/lato/Lato-Black.woff') format('woff'), /* Pretty Modern Browsers */
//        url('./fonts/lato/Lato-BlackItalic.woff') format('woff'); /* Pretty Modern Browsers */
//   font-weight: 900;
// }

// eof